










import Vue from 'vue';
import Component from 'vue-class-component';
import { RejectResponse } from 'common-modules/src/store/moduleStore';
import { Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import Concentrations from 'common-modules/src/store/Concentrations';
import jwlSectionNav, { NavTab } from '../components/jwlSectionNav.vue';

const CommonError = () => import('common-modules/src/components/CommonError.vue');

@Component({
  components: {
    jwlSectionNav,
    CommonError,
  },
  computed: mapGetters([
    'baseConcentrations',
  ]),
})
export default class LmsCourse extends Vue {
  baseConcentrations!: Concentrations;

  menuNodes: NavTab[] = [
    {
      target: 'concentration_home',
      label: 'course.nav.home',
    },
    {
      target: 'concentration_itinerary',
      label: 'course.nav.itinerary',
    },
  ];

  loaded = false;
  error: RejectResponse | null = null;

  @Watch('$route')
  async fetchData (): Promise<void> {
    this.loaded = false;
    this.$store.dispatch('getData', `concentration/${this.$route.params.concentration}`)
      .then((data) => {
        this.$store.commit('SET_CONCENTRATION', data);
        this.error = null;
        this.loaded = true;
      })
      .catch((e) => {
        this.error = e;
      });
  }

  get rootClasses (): Record<string, boolean> {
    const concentrationCode = this.$route.params.concentration;
    const concentration = this.baseConcentrations.dataAsFlatArray().find((baseConcentration) => baseConcentration.code === concentrationCode);

    return {
      'sis-course--bachelor': !!concentration && concentration.programmeType === 'bachelor',
      'sis-course--professional': !!concentration && concentration.programmeType === 'professional',
      'sis-course--teacher-training': !!concentration && concentration.programmeType === 'teacher-training',
      'sis-course--academy': !!concentration && concentration.programmeType === 'academy',
    };
  }

  created (): void {
    this.fetchData();
  }
}
